import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Select from 'react-select'
import { Checkbox } from 'pretty-checkbox-react'
import makeAnimated from 'react-select/animated'
import axios from 'axios'

import '@djthoms/pretty-checkbox';

const cbExperienceOptions = [
  {value: 'beginner', label: 'Beginner (Less than 1 Year)'},
  {value: 'intermediate', label: 'Intermediate (1-2 Years)'},
  {value: 'advanced', label: 'Advanced (2-3 Years)'},
  {value: 'expert', label: 'Expert (4+ Years)'},
]
const languageOptions = [
  {value: 'java', label: 'Java'},
  {value: 'scala', label: 'Scala'},
  {value: 'csharp', label: 'C#'},
  {value: 'c', label: 'C/C++'},
  {value: 'js', label: 'JavaScript'},
  {value: 'php', label: 'PHP'},
  {value: 'python', label: 'Python'},
  {value: 'ruby', label: 'Ruby'},
  {value: 'go', label: 'Go'},
  {value: 'swift', label: 'Swift'},
  {value: 'objective-c', label: 'Objective-C'},
  {value: 'kotlin', label: 'Kotlin'}
]
const programmingExperienceOptions = [
  {value: '<1', label: 'Less Than 1 Year'},
  {value: '1-2', label: '1 or 2 Years'},
  {value: '2-3', label: '2 or 3 Years'},
  {value: '>4', label: 'More Than 4 Years'}
]
const animatedComponents = makeAnimated();

const CompleteProfile = ({ location }) => {
  const [cbExperience, setCbExperience] = useState("");
  const [languages, setLanguages] = useState([]);
  const [programmingExperience, setProgrammingExperience] = useState("");
  const [didAcceptTerms, setDidAcceptTerms] = useState(false);
  const [didSubscribeToNewsletter, setDidSubscribeToNewsletter] = useState(true);

  const email = decodeURIComponent(location.search.split('&')[0].substring(7))
  const state = location.search.split('&')[1]

  // Block access to this page without state attribute in url
  useEffect(() => {
    if (!email || !state) {
      navigate('/')
    }
  }, [email, state])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!cbExperience || languages.length === 0 || !programmingExperience) {
      setWarningText("Looks like 1 or more fields are empty.")
      showWarning()
    } else if(!didAcceptTerms) {
      setWarningText("You must accept the terms to complete your registration.")
      showWarning()
    } else {
      document.getElementById('finishButton').disabled = true;
      let additionalInfo = {
        couchbaseExperience: cbExperience.value,
        favoriteLanguages: languages.map(lang => lang.value),
        programmingExperience: programmingExperience.value,
        acceptedTerms: {
          didAcceptTerms,
          acceptedAt: Date.now()
        },
        newsletter: didSubscribeToNewsletter
      }
      axios.put(`${process.env.GATSBY_AUTH_API_URL}/users/${email}/additionalInformation`, additionalInfo, {headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${process.env.GATSBY_APP_JWT}`}}).then((res) => {
        redirectToContinueAuth()
      }).catch((err) => {
        setWarningText("An Unknown Error Occurred.")
        showWarning()
        console.error('Could not Complete Profile', err)
      })

    }
  }

  const redirectToContinueAuth = () => {
    const redirectUrl = `https://${process.env.GATSBY_AUTH0_DOMAIN}/continue?${state}`
    navigate(redirectUrl)
  }

  const handleCbExperienceChange = (selectedExperience) => {
    setCbExperience(selectedExperience)
  }

  const handleLanguagesChange = (selectedLanguages) => {
    setLanguages(selectedLanguages)
  }

  const handleProgExperienceChange = (selectedExperience) => {
    setProgrammingExperience(selectedExperience)
  }
  
  const handleTermsChange = (event) => {
    setDidAcceptTerms(event.currentTarget.checked)
  }

  const handleNewsletterChange = (event) => {
    setDidSubscribeToNewsletter(event.currentTarget.checked)
  }

  const hideWarning = () => {
    document.getElementById("warningWrapper").classList.add("hidden")
  }

  const showWarning = () => {
    document.getElementById("warningWrapper").classList.remove("hidden")
  }

  const setWarningText = (text) => {
    document.getElementById("warningText").innerText = text;
  }

  return (
    <div className="w-full xs:w-11/12 max-w-md mx-auto my-16">
      <h2 className="mb-6 text-2xl font-bold">Complete your Couchbase Profile</h2>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
        {/* Warning/Error Message */}
        <div id="warningWrapper" className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6 hidden" role="alert">
          <strong className="font-bold">Uh oh! </strong>
          <span className="block sm:inline" id="warningText"> </span>
          <span role="button" tabIndex={0} onClick={hideWarning} onKeyDown={hideWarning} className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-red-500"
                 role="button"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
        {/* Couchbase Experience */}
        <div className="mb-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="username">
            Couchbase Experience Level
          </label>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={cbExperienceOptions}
            onChange={handleCbExperienceChange}
            value={cbExperience}
          />
        </div>
        {/* Favorite Programming Languages */}
        <div className="mb-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="username">
            Favorite Programming Language(s)
          </label>
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            value={languages}
            options={languageOptions}
            onChange={handleLanguagesChange}
          />
        </div>
        {/* Years of Programming Experience */}
        <div className="mb-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="username">
            Years of Programing Experience
          </label>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            options={programmingExperienceOptions}
            onChange={handleProgExperienceChange}
            value={programmingExperience}
          />
        </div>
        {/* Newsletter Checkbox */}
        <div className='mb-6 text-sm sm:text-base'>
          <Checkbox defaultChecked={didSubscribeToNewsletter} bigger shape='curve' animation='pulse' color='success' className='mt-3' onChange={handleNewsletterChange}>&nbsp;Subscribe to the Couchbase newsletter</Checkbox>
        </div>
        {/* Terms and Conditions Checkbox */}
        <div className='mb-6 text-sm sm:text-base'>
          By signing up, you accept the <a className='text-blue-500 hover:underline' href='https://www.couchbase.com/enterprise-terms' target='_new'>Terms of Use</a> and acknowledge the <a href='https://www.couchbase.com/privacy-policy' className='text-blue-500 hover:underline' target='_new'>Couchbase Privacy Policy</a>.
          <br/>
          <Checkbox bigger shape='curve' animation='pulse' color='success' className='block mt-3' onChange={handleTermsChange}>&nbsp;I Accept</Checkbox>
        </div>
        <div className="flex items-center justify-between">
          <button id="finishButton" className="cb-red-button" type="submit">Finish Registration</button>
        </div>
      </form>
    </div>
  )
}

export default CompleteProfile
